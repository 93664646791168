export const COLORS = {
  BLACK: '#000000',
  WHITE: '#FFFFFF',
  BLUE: '#5FBEEC',
  LIGHT_GREY: '#CDD2DB',
  GREY: '#A0ABBF',
  GREY_70: '#B3B2AC',
  DARK_GREY: '#4B4F55',
  DARKER_GREY: '#4A4A4A',
  SLATE_DARK: '#79818D',
  SLATE: '#9AA4B4',
  SAND_DARK: '#929088',
  SAND: '#F2EEE6',
  SAND_LIGHT: '#F4F4F1',
  BLACK_15_OPACITY: 'rgba(0, 0, 0, 0.15)',

  PRIMARY: {
    RED: '#D0021B'
  },
  DASHBOARDS: {
    INCREASE: '#14AC51',
    DECREASE: '#DB4322',
    SAME: '#808793',
    DARK: '#424242'
  },

  // From Rex app theme
  BORDERS: {
    GREY: '#B4B1A9'
  },

  // These are being used for the pipeline coloured bars, but will be used elsewhere down the track.
  NEW_REX_BRAND: {
    LIGHT_GREY: '#B1B0BD',
    BLUE_PRIMARY: '#213A6A',
    SKY_BLUE: '#5FBEEC',
    CORAL_PINK: '#F7A7A3',
    SUNSHINE_YELLOW: '#FFC62F',
    CALENDAR_BLUE: '#3B78DD',
    CALENDAR_BLUE_10: '#3B78DD1A'
  },

  LOGIN: {
    POCKET_BLUE: '#029FBB',
    BUTTON_PRIMARY: '#5FBEEC',
    BUTTON_HOVER: '#5FBEEC',
    BUTTON_FOCUS: 'rgba(95,190,236,0.40)',
    STATES: {
      IDLE: '#B4BBC7',
      HOVER: '#9AA4B4',
      ACTIVE: '#3F4A55'
    }
  },

  STATUS: {
    RED: '#B13024',
    GREEN: '#96BC57'
  }
};

export const UTILS = {
  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
};

export const PADDINGS = {
  '3XS': '4px',
  XXS: '5px',
  XS: '10px',
  S: '15px',
  M: '20px',
  L: '25px',
  XL: '30px',
  XXL: '35px',
  '3XL': '40px'
};

export const BORDER_RADIUS = {
  BUTTONS: '2px',
  TAGS: '3px'
};

export const FONT = {
  SIZES: {
    SMALL: '12px',
    TAG: '13px',
    REGULAR: '14px',
    LARGE: '16px',
    XL: '18px',

    NAV_MENU: {
      TIER_ONE: '15px',
      TIER_TWO: '14px'
    }
  },
  LINE_HEIGHTS: {
    NAV_MENU: '22px'
  },
  WEIGHTS: {
    LIGHT: 100,
    REGULAR: 400,
    MEDIUM: 500,
    SEMIBOLD: 600,
    BOLD: 900
  },
  SPACING: {
    REGULAR: '0.35px',
    LARGE: '0.5px'
  },
  TRANSFORM: {
    UPPERCASE: 'uppercase'
  },
  FAMILY: {
    PROXIMA_NOVA: 'proxima-nova, lato, arial, serif'
  }
};

export const TEXTS = {
  HEADING: {
    lineHeight: '28px',
    fontSize: '27px',
    fontWeight: 600,
    color: COLORS.DARK_GREY
  },

  HEADING_1: {
    lineHeight: '40px',
    fontSize: '36px',
    fontWeight: 900
  },

  HEADING_2: {
    lineHeight: '20px',
    fontSize: '17px'
  },

  HEADING_3: {
    lineHeight: '14px',
    fontSize: '12px'
  },

  HEADING_4: {
    lineHeight: '14px',
    fontSize: '12px'
  },

  BODY: {
    lineHeight: '19px',
    fontSize: '14px',
    fontWeight: 400
  },

  LOGIN: {
    AVATAR: {
      color: COLORS.WHITE,
      fontSize: '16px',
      lineHeight: '28px',
      fontWeight: 500
    }
  },

  FIELD: {
    LABEL: {
      color: COLORS.GREY,
      fontSize: '16px',
      lineHeight: '19px',
      fontWeight: 400
    },
    LABEL_DARK: {
      color: COLORS.DARK_GREY,
      fontSize: '16px',
      lineHeight: '19px',
      fontWeight: 400
    },
    ERROR: {
      color: COLORS.PRIMARY.RED,
      fontSize: '14px',
      lineHeight: '17px',
      fontWeight: 400
    }
  },

  INPUT: {
    PLACEHOLDER: {
      lineHeight: '17px',
      fontSize: '14px',
      fontWeight: FONT.WEIGHTS.REGULAR,
      fontFamily: FONT.FAMILY.PROXIMA_NOVA,
      fontStyle: 'normal',
      color: '#B4B1A9'
    }
  },

  LINK: {
    lineHeight: '16px',
    fontSize: '13px',
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    color: COLORS.DARK_GREY,
    textDecoration: 'underline',
    cursor: 'pointer'
  },

  UNDECORATED: {
    textDecoration: 'none'
  },

  NORMAL: {
    fontStyle: 'normal'
  },

  ITALIC: {
    fontStyle: 'italic'
  },

  REGULAR: {
    fontWeight: FONT.WEIGHTS.REGULAR
  },

  MEDIUM: {
    fontWeight: FONT.WEIGHTS.MEDIUM
  },

  SEMIBOLD: {
    fontWeight: FONT.WEIGHTS.SEMIBOLD
  },

  BOLD: {
    fontWeight: FONT.WEIGHTS.BOLD
  },

  SMALL: {
    fontSize: '12px'
  },

  LARGE: {
    fontSize: '16px'
  },

  WHITE: {
    color: COLORS.WHITE
  },

  BLACK: {
    color: COLORS.BLACK
  },

  GREY: {
    color: COLORS.GREY
  },

  DARK_GREY: {
    color: COLORS.DARK_GREY
  },

  DARKER_GREY: {
    color: COLORS.DARKER_GREY
  }
};
